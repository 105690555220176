<ng-container *ngIf="dataStream$ | async as dataList">
  <div class="title-container">
    <span class="title">{{
      'orderSearch.client' | translate
    }}</span>
    <span class="title">{{
      'orderSearch.order' | translate
    }}</span>
    <span class="title mobile-hidden">{{
      'orderSearch.initiatingLocation' | translate
    }}</span>
    <span class="title mobile-hidden">{{
      'orderSearch.pickupLocation' | translate
    }}</span>
    <div class="mobile-smaller title desktop-hidden">
      <mat-icon>store</mat-icon>
    </div>
    <div class="mobile-smaller title desktop-hidden">
      <mat-icon>shopping_bag</mat-icon>
    </div>
    <span class="title red-text mobile-hidden">{{
      'orderSearch.returnTitle' | translate
    }}</span>
    <span class="mobile-medium title">{{ 'orderSearch.date' | translate }}</span>
  </div>
  <cdk-virtual-scroll-viewport
    class="order-table"
    *ngIf="this.currentArray.length > 0"
    [itemSize]="120"
    (scrolledIndexChange)="nextBatch()">
    <li *cdkVirtualFor="let data of dataList; let index = index;">
      <mat-card
        class="order-container"
        (click)="saleClicked(data.sale.id, data.sale.type, data.sale.initiatingLocation, data.sale.registerId, data.sale.date)">
        <div class="info-container">
          <div class="info">
            <span class="name text" data-dd-action-name="Client Name" data-dd-privacy="mask">{{ getName(data.client) }}</span>
            <span class="text" data-dd-action-name="Client Phone" data-dd-privacy="mask">{{ getPhone(data.client) }}</span>
            <div class="ellipsis-text-box">
              <span class="ellipsis-text" data-dd-action-name="Client Email" data-dd-privacy="mask">{{ getEmail(data.client) }}</span>
            </div>
          </div>
          <div class="info">
            <span class="text">{{(data.sale.type === "RETAILTRANSACTION" || data.sale.type === "RETAILORDER" ? 'orderSearch.retail'  : 'orderSearch.ecomm') | translate}}</span>
            <span *ngIf="data.sale.orderType" class="text mobile-hidden">
              {{ data.sale.orderType |translate }} - {{ data.sale.status | translate }}
            </span>
            <span *ngIf="!data.sale.orderType && data.sale.status" class="text mobile-hidden">
              {{ data.sale.status | translate }}
            </span>
            <span class="text">{{ data.sale.type === "RETAILORDER" ? data.sale.saleChId : data.sale.id }}</span>
          </div>
          <div class="mobile-smaller info">
            <span class="text">{{data.sale.initiatingLocation}}</span>
          </div>
          <div class="mobile-smaller info">
            <span class="text">{{data.sale.pickupLocation}}</span>
          </div>
          <div class="info mobile-hidden">
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[0]" class="ellipsis-text red-text">{{data.sale.returns[0].name}}</span>
            </div>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[1]" class="ellipsis-text red-text">{{data.sale.returns[1].name}}</span>
            </div>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[2]" class="ellipsis-text red-text">{{data.sale.returns[2].name}}</span>
            </div>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[3]" class="ellipsis-text red-text">{{data.sale.returns[3].name}}</span>
            </div>
          </div>
          <div class="mobile-medium info">
            <span class="text">{{ data.sale.date }}</span>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[0]" class="desktop-hidden ellipsis-text red-text">{{data.sale.returns[0].name}}</span>
            </div>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[1]" class="desktop-hidden ellipsis-text red-text">{{data.sale.returns[1].name}}</span>
            </div>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[2]" class="desktop-hidden ellipsis-text red-text">{{data.sale.returns[2].name}}</span>
            </div>
            <div class="ellipsis-text-box">
              <span *ngIf="data.sale.returns[3]" class="desktop-hidden ellipsis-text red-text">{{data.sale.returns[3].name}}</span>
            </div>
          </div>
        </div>
      </mat-card>
    </li>
    <div class="spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
<div class="spinner" *ngIf="loading && currentArray.length === 0">
  <mat-spinner></mat-spinner>
</div>
