import { ISalesHistoryData } from "../services/sales-history.service";
import { Order } from "./order.model";
import { Transaction } from "./transaction.model";

export interface ISalesHistoryReturnItem {
  id: string;
  name: string;
  quantity: number;
  color: string;
  size: string;
}

export class SalesHistory {
  private _id: string;
  private _type: string;
  private _orderType: string;
  private _status: string;
  private _initiatingLocation: string;
  private _pickupLocation: string;
  private _registerId: string;
  private _returns: ISalesHistoryReturnItem[];
  private _date: string;
  private _customerId: string;
  private _saleeChId: string;

  constructor( data: ISalesHistoryData, type: string, order?: Order, transactions?: Transaction[]) {
    this._id = data.id;
    this._type = type;
    this._date = data.attributes.date;
    this._customerId = data.attributes.customerReferenceId ? data.attributes.customerReferenceId : data.attributes.customerId;
    if (type === "ECOMMORDER") {
      this._orderType = order.orderType;
      this._status = order.status;
      this._initiatingLocation = order.initiatingStore;
      this._pickupLocation = order.pickupStore;
      this._registerId = '';
      this._returns = this._calculateReturnsForOrder(order);
    } else if(type === "RETAILTRANSACTION" ) {
      this._status = 'orderDetail.transaction';
      this._initiatingLocation = data.attributes.locationId;
      this._pickupLocation = '';
      this._registerId = data.attributes.registerId;
      this._returns = this._calculateReturnsForTransaction(transactions);
    } else if(type === "RETAILORDER") {
      this._orderType = order.orderType;
      this._status = order.status;
      this._initiatingLocation = order.initiatingStore;
      this._pickupLocation = order.pickupStore;
      this._registerId = '';
      this._saleeChId = order.saleChId;
      this._returns = this._calculateReturnsForTransaction(transactions);
    }
  }

  public get id(): string {
    return this._id;
  }

  public get type(): string {
    return this._type;
  }

  public get status(): string {
    return this._status;
  }

  public get orderType(): string {
    return this._orderType;
  }

  public get initiatingLocation(): string {
    return this._initiatingLocation;
  }

  public get pickupLocation(): string {
    return this._pickupLocation;
  }

  public get registerId(): string {
    return this._registerId;
  }

  public get returns(): ISalesHistoryReturnItem[] {
    return this._returns;
  }

  public get date(): string {
    return this._date;
  }

  public get customerId(): string {
    return this._customerId;
  }

  public get saleChId(): string {
    return this._saleeChId;
  }

  private _calculateReturnsForOrder(order: Order) {
    return order.returnItems.map((item) => ({
      id: item.id,
      name: item.name.toLowerCase().replace(/(^\w|\s\w|-\w)/g, (m: string) => m.toUpperCase()),
      quantity: item.quantity,
      color: item.color,
      size: item.size
    }))
  }
  private _calculateReturnsForTransaction(transactions: Transaction[]) {
    let arr = [];
    transactions.forEach((transaction) => transaction.returnItems.forEach((item) => arr.push({
      id: item.productId,
      name: item.name.toLowerCase().replace(/(^\w|\s\w|-\w)/g, (m: string) => m.toUpperCase()),
      quantity: parseInt(item.quantity.toString().replace('-', '')),
      color: item.colour,
      size: item.size
    })));
    return arr;
  }
}
